import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Requests for user information — Uploadcare’s transparecy report',
  description: 'United States national security requests for user information.',
  header: 'United States national security requests for user information'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A variety of laws allow government agencies around the world to request user
information for civil, administrative, criminal, and national security purposes.
We separately report requests from US authorities using national security laws
because these laws restrict how much information companies like us are allowed
to share, and when we are allowed to share it. In cases of national security,
the US government can use the Foreign Intelligence Surveillance Act (FISA) to
request non-content and content information, and use National Security Letters
(NSLs) to request limited information about a user’s identity.`}</p>
    <h2 {...{
      "id": "transparecy-report"
    }}><a parentName="h2" {...{
        "href": "#transparecy-report"
      }}>{`Transparecy report`}</a></h2>
    <p><strong parentName="p">{`Non-content requests under FISA — 0`}</strong>{` (A FISA request can include non-content
metadata—for example, the "from" and "to" fields in an email header and the IP
addresses associated with a particular account).`}</p>
    <p><strong parentName="p">{`Content requests under FISA — 0`}</strong>{` (A FISA request can include a demand for a
user’s content).`}</p>
    <p><strong parentName="p">{`Requests made via National Security Letters (NSL) — 0`}</strong>{` (Using a NSL, the FBI
can seek "the name, address, length of service, and local and long distance toll
billing records" of a subscriber to a wire or electronic communications service.
The FBI can't use NSLs to obtain anything else from Uploadcare).`}</p>
    <h2 {...{
      "id": "submitting-law-enforcement-requests-to-uploadcare"
    }}><a parentName="h2" {...{
        "href": "#submitting-law-enforcement-requests-to-uploadcare"
      }}>{`Submitting law enforcement requests to Uploadcare`}</a></h2>
    <p>{`For promptest processing, law enforcement requests for customer information
should be sent by email to `}<a parentName="p" {...{
        "href": "mailto:trust@uploadcare.com"
      }}>{`trust@uploadcare.com`}</a>{`.`}</p>
    <p>{`If your agency must submit requests via mail, our address is as follows:
Uploadcare, Inc 18401 Collins Ave, Ste. 100-241, Sunny Isles Beach, FL, 33160
United States of America. Please email `}<a parentName="p" {...{
        "href": "mailto:trust@uploadcare.com"
      }}>{`trust@uploadcare.com`}</a>{` to advise you
have submitted a request via mail or intend to serve in person. Requests
received by post are subject to additional processing time. Acceptance of legal
process by any of these means is for convenience and does not waive any
objections, including lack of jurisdiction or proper service.`}</p>
    <p>{`Please note that Uploadcare will only respond to requests that are sent from a
government agency via registered email domain; are issued where Uploadcare is
subject to jurisdiction; have an enforceable subpoena, court order, or search
warrant compelling us to produce the information requested; and state the
categories of records sought and specific time period. Uploadcare's
`}<a parentName="p" {...{
        "href": "/about/privacy-policy/"
      }}>{`Privacy Policy`}</a>{` compels us to notify our customer of requests
unless explicitly prohibited from doing so by law. Agencies that do not want us
to notify our customer(s) of a request should reference explicit legal
authority barring us from disclosing. If your request does not prohibit
disclosure and you do not want us to notify our customer, reply stating you
withdraw your request or that you will obtain a non-disclosure order.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      